module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6QRKRSGTWR"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jeremy Grancher","short_name":"JG","start_url":"/","background_color":"#F8F8F8","theme_color":"#25CED1","display":"minimal-ui","icon":"static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"faec65183d36c657c2c00442abbe6acb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"injectColorFlashScript":false,"preset":{"colors":{"white":"#FFFFFF","black":"#000","text":"#000","textSecondary":"#C1C1C1","background":"#F8F8F8","primary":"#25CED1","paradisePink":"#EA526F","turquoise":"#48E5C2","coral":"#FF8A5B","darkTurquoise":"#25CED1","semiPermanent":"#BFBFBF","cwgYellow":"#FFD85E","cwgBlue":"#4285F4","qantasRed":"#FF0002","ustwoGreen":"#14C04D"},"fonts":{"body":"'TT Norms Pro', Helvetica, Arial, sans-serif","monospace":"'Source Code Pro', Menlo, Monaco, 'Courier New', monospace"},"fontSizes":{"xxs":12,"xs":14,"sm":16,"md":18,"lg":24,"xl":32,"xxl":48,"xxxl":64,"xxxxl":80},"fontWeights":{"body":400,"bold":700,"extraBold":800},"letterSpacings":{"zero":0,"sm":0.2},"lineHeights":{"xs":1.125,"sm":1.25,"md":1.5,"lg":1.75,"xl":2},"space":[0,8,16,32,40,64,128,256],"sizes":[0,8,16,32,40,64,128,256],"breakpoints":["40em","56em","64em","85.375em"],"styles":{"root":{"backgroundColor":"white","padding":[null,null,3]}},"config":{"useColorSchemeMediaQuery":false,"useCustomProperties":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
